.barcode-scanner-popup-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.barcode-scanner-popup-container,
.barcode-scanner-popup-viewport {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.barcode-scanner-popup-viewport canvas,
.barcode-scanner-popup-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.barcode-scanner-popup-viewport canvas.drawingBuffer,
.barcode-scanner-popup-container video.drawingBuffer {
  width: 100%;
  height: 100%;
}
